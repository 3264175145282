<template>
  <div class="all">
    <Home2Header active-index="/news"></Home2Header>
    <NewsHeader2 active-index2="/news"></NewsHeader2>
    <div class="main">
      <div :style="background" class="bg">
        <div class="text">
          <p style="font-size: 28px">
            双动新闻
          </p>
          <p>双动最新动态消息，为您提供关于双动公司的第一手资讯</p>
        </div>

      </div>
      <div style="margin: 0 5%;text-align: left" v-loading="loader" v-if="0">

        <el-row :gutter="50" v-for="(news, index) of newses" :key="index" style="margin-top: 30px">
          <el-col :span="8" v-for="item of news" :key="item.id">
            <a @click="getNews(item.address)">
              <div class="shadow" style="border: 1px solid #e5e5e5;border-radius: 4px;transition: .3s;">
                <el-image :src="item.img" lazy style="display: block;width: 100%;height: 17.5vw;min-height:168px;object-fit: cover"/>
                <div style="background-color: #f1f1f1;border-top: 1px solid #e5e5e5;padding: 8px">
                  <h3 style="margin-bottom: 16px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{ item.title }}</h3>
                  <p>{{ item.created_at }}</p>
                </div>
              </div>
            </a>
          </el-col>
        </el-row>

      </div>
      <div style="margin-bottom: 50px" v-loading="loader2" v-if="0">
        <el-button v-if="moreShowBoolen" @click="moreShow" type="info" round
                   style="background-color: rgb(60,60,60);color: #fff;margin-top: 20px">加载更多
        </el-button>
<!--        <div v-else-if="moreShowBoolen==false" style="margin-top: 20px">没有更多了</div>-->
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../../components/Footer";
import NewsHeader2 from "../../components/NewsHeader2";
import Home2Header from "@/components/Home2Header";

export default {
  name: "News",
  data() {
    return {
      loader: false,
      loader2: false,
      moreShowBoolen: false,
      nowPage: 0,

      background: {
        background: 'url(' + require('../../assets/News/1.jpg') + ')',
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        height: '23.44vw',
        width: '100%',
        minHeight: '225px',
        minWidth: '960px',
      },
      news: [],
    }
  },
  mounted() {
    this.init();
  },
  components: {
    NewsHeader2,
    Home2Header,  Footer
  },
  props: {
    activeIndex: {
      type: String
    },
    activeIndex2: {
      type: String
    }
  },
  computed: {
    newses() {
      const newses = []
      this.news.forEach((item, index) => {
        const news = Math.floor(index / 3)//3代表3条为一行，随意更改
        if (!newses[news]) {
          newses[news] = []
        }
        newses[news].push(item)
      })
      return newses
    }
  },
  methods: {
    init() {
      this.loader = true;
      this.$axios.post('web-news').then(res => {
        this.loader = false;
        // console.log(res)
        this.news = res.data.data.news;
        this.nowPage += res.data.data.news.length;
        if (res.data.data.news.length < 6) { // 6条数据一页
          this.moreShowBoolen = false
        } else {
          this.moreShowBoolen = true
        }
      }).catch(error => {
        this.loader = false;
        console.log(error);
      });
    },
    moreShow() { // 点击查询更多
      this.loader2 = true;
      this.$axios.post('web-news', {start: this.nowPage}).then(res => {
        this.loader2 = false;
        // console.log(res)
        this.news = this.news.concat(res.data.data.news);
        this.nowPage += res.data.data.news.length;
        if (res.data.data.news.length >= this.nowPage * 6) {
          this.moreShowBoolen = true
        } else {
          this.moreShowBoolen = false
        }
      }).catch(error => {
        this.loader2 = false;
        console.log(error);
      });
    },

    getNews(address){
      this.$router.push({path: '/news2',query: {"address": address}});
    },
  }
}
</script>

<style scoped>
.all {
  min-width: 960px;
}

.main {
  margin: 0;
}

.shadow:hover {
  box-shadow: 0 0 8px 3px #d7d7d7;
}

.bg {
  height: 23.44vw;
  width: 100vw;
  min-height: 225px;
  min-width: 960px;
  position: relative;
}

.text {
  position: absolute;
  z-index: 2;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.background h3 {
  margin: 0;
  padding: 10px 0 10px 10px;
}

.background p {
  padding: 0 0 10px 10px;
  color: #848484
}

a, a:hover, a:active, a:visited, a:link, a:focus {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;
  background: none;
  text-decoration: none;
  color: #3e3e3e;
}
</style>